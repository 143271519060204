import React from 'react'

export default function Social(props) {
    return (
        <div className="social-icons flex mt-10">
                <a href="https://www.instagram.com/yesabsolutelydesigns/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon instagram" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke={props.color === 'yellow' ? '#000000' : '#ffffff'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <rect x="4" y="4" width="16" height="16" rx="4" />
                        <circle cx="12" cy="12" r="3" />
                        <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                    </svg>            
                </a>
                <a href="https://www.facebook.com/Yes-Absolutely-Signs-Designs-102356145411832" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon facebook" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke={props.color === 'yellow' ? '#000000' : '#ffffff'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                    </svg>                
                </a>                
        </div>
    )
}
