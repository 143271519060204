import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

import '../styles/tailwind.css'
import '../styles/variables.scss'
import '../styles/main.css'

import Logo from './logo'
import Seo from './seo'
import Social from './social'

import Car1 from '../images/car-hero-image.jpg'
import Car2 from '../images/car-hero-image-2.jpg'
import Car3 from '../images/car-hero-image-3.jpg'

import VideoBg from "reactjs-videobg";
import webm from "../images/video.webm";
import mp4 from "../images/video.mp4";
import poster from "../images/poster.png";

export default function Layout() {

  // Sidebar Toggle
  const [isOpen, setOpen] = useState(false);
  const burgerClass = () => {
      setOpen(!isOpen);
  };  

  // Theme Selection
  const [theme, setTheme] = useState('black')
  function themePicker(e) {
    setTheme(e.target.className)
  }

  return (
    <>
    <Seo title="Home" />
      <VideoBg poster={poster} wrapperClass="yes-video" >
        <VideoBg.Source src={webm} type="video/ogg" />
        <VideoBg.Source src={mp4} type="video/mp4" />
      </VideoBg>
      <div className={`overlay ${theme}`} />
     <div className="site-icons flex justify-end absolute right-0 z-10">
          <span className="toggler burger" onClick={burgerClass}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke={theme === 'yellow' ? '#000000' : '#ffffff'} fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="4" y1="6" x2="20" y2="6" />
              <line x1="4" y1="12" x2="20" y2="12" />
              <line x1="4" y1="18" x2="20" y2="18" />
            </svg>
          </span>
        </div>
      <main className='themer relative flex flex-col items-center justify-between min-h-screen'>
          <div className={`${theme} w-full min-h-screen text-wrap flex flex-col justify-center items-center text-center container`}>
            <Logo style={theme} />
            <h1 className="my-16">Specializing in vehicle wraps, large format printing <br/> and commercial signage</h1>
            <h3>Not sure about your business marketing needs? Ask about our free 15 minute consultation today!</h3>
            <a href="mailto:rhys@yesabsolutely.ca" className="mt-5 inline-flex p-5 button">Email YA! Today</a>
          </div>
          <aside className={`${theme} sidebar p-24 ${isOpen ? 'open' : 'closed'}`}>
          <div className="flex flex-row-reverse items-center">
            <svg onClick={burgerClass} xmlns="http://www.w3.org/2000/svg" className="icon" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke={theme === 'yellow' ? '#000000' : '#ffffff'} fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <rect x="4" y="4" width="16" height="16" rx="2" />
              <path d="M10 10l4 4m0 -4l-4 4" />
            </svg>
            <span className="color-picker">
            <span className="cyan" id={`${theme === 'cyan' ? 'activeColor' : 'inactiveColor' }`} onClick={themePicker} />
            <span className="magenta" id={`${theme === 'magenta' ? 'activeColor' : 'inactiveColor' }`} onClick={themePicker} />
            <span className="yellow" id={`${theme === 'yellow' ? 'activeColor' : 'inactiveColor' }`} onClick={themePicker} />
            <span className="black" id={`${theme === 'black' ? 'activeColor' : 'inactiveColor' }`} onClick={themePicker} />
            </span>
          </div>
          
          {/* Start Sidebar */}
          <section className="pt-10">
            <h2>About Yes Absolutely!</h2>
            <hr />
            <p>With 30 years of print and design experience, you can count on Rhys and the team at Yes Absolutely! for high energy, high quality signs and designs to catapult your business into the forefront.</p>
            <p>Whatever your marketing budget, we will work with you to find the right print products to get your brand noticed.</p>
            <p>Using modern equipment and technologies, we combine tried and true methods with what’s on trend; we love what we do, and it shows</p>
          </section>
          <section className="my-10">
            <h2>Why Work With Yes Absolutely?</h2>
            <hr />
            <ul>
              <li>Our extensive print and graphic design experience</li>
              <li>We are passionate about what we do and it shows</li>
              <li>We are committed to giving you the best bang for your buck and maximizing your marketing dollars.</li>
              <li>You get elevated designs at comparable pricing to the other print shops in town</li>
            </ul>
          </section>
          <section>
            <h2>Get In Touch</h2>
            <hr />
            <h5>Address</h5>
            <p>North Bay, ON</p>
            <h5>Phone</h5>
            <p><a href="tel:+17053000096">705.300.0096</a></p>
            <h5>Toll Free</h5>
            <p><a href="tel:+18557037446">1.855.703.7446</a></p>
          </section>
          <a href="mailto:rhys@yesabsolutely.ca" className="mt-5 inline-flex p-5 button">Email YA! Today</a>
          <Social
            color={theme}
          />
          {/* End Sidebar*/}
          </aside>
          </main>
    </>
  )
}